import React, { Component } from "react";
import Head from "next/head";
import moment from "moment";
import { withRouter } from "next/router";
import { NextSeo } from "next-seo";
import EventPageTrendy from "../src/pages/DedicatedEventPage/TrendyTemplate/EventPageTrendy";
import { getEventData, getTicketData } from "../src/crud/dedicatedEvent-crud";
import { baseUrl, GoogleAnalyticsId } from "../src/config/config";
import { convertToPlainSeo, eventUrlValidation } from "../src/helpers/common";
import EventPagePro from "../src/pages/DedicatedEventPage/ProTemplate/EventPagePro";
import StructuredData from "../src/components/StructuredData";
import { getQuery, initGA } from "../src/helpers/utils";
import EventPageMinimalistic from "../src/pages/DedicatedEventPage/MinimalTemplate/EventPageMinimalistic";
import { TEMPLATE_TYPES } from "../src/config/constants";
class EventPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            template_type: null,
            gaID: null,
            gtmID: null,
            pixelID: null
        };
    }

    structuredData = {
        "@context": "https://schema.org",
        "@type": "Event",
        name: this.props.eventData.name,
        startDate: `${moment
            ?.tz(
                this.props.eventData.end_date
                    ? `${this.props.eventData.start_date}T${this.props.eventData.start_time}`
                    : null,
                "UTC"
            )
            ?.tz(this.props.eventData.time_zone ?? null)
            ?.format()}`,
        endDate: `${moment
            ?.tz(
                this.props.eventData.end_date
                    ? `${this.props.eventData.end_date}T${this.props.eventData.end_time}`
                    : null,
                "UTC"
            )
            ?.tz(this.props.eventData.time_zone ?? null)
            ?.format()}`,
        eventAttendanceMode: this.props.eventData.is_virtual
            ? "https://schema.org/OnlineEventAttendanceMode"
            : "https://schema.org/OfflineEventAttendanceMode",
        eventStatus: "https://schema.org/EventScheduled",
        location: this.props.eventData.is_virtual
            ? {
                "@type": "VirtualLocation",
                ...(this.props.eventData.event_live_link
                    ? { url: this.props.eventData.event_live_link }
                    : this.props.eventData.event_website
                        ? { url: this.props.eventData.event_website }
                        : "")
            }
            : {
                "@type": "Place",
                ...(this.props.eventData.venue && {
                    name: this.props.eventData.venue
                }),
                address: {
                    "@type": "PostalAddress",
                    streetAddress: this.props.eventData.streetAddress
                        ? this.props.eventData.streetAddress
                        : "",
                    addressLocality: this.props.eventData.city ? this.props.eventData.city : "",
                    addressRegion: this.props.eventData.region
                        ? this.props.eventData.region
                        : "",
                    postalCode: this.props.eventData.postalCode
                        ? this.props.eventData.postalCode
                        : "",
                    addressCountry: this.props.eventData.country
                        ? this.props.eventData.country
                        : ""
                }
            },
        ...(this.props.eventData.event_all_posters && {
            image: this.props.eventData.event_all_posters
        }),
        ...(this.props.eventData.description && {
            description: convertToPlainSeo(this.props.eventData.description)
        }),
        performer: {
            "@type": "Person",
            name: this.props.eventData.organiser_name
        },
        organizer: {
            "@type": "Organization",
            name: this.props.eventData.organiser_name,
            ...((this.props.eventData.organiser_website ||
                this.props.eventData.organiser_facebook_url ||
                this.props.eventData.organiser_linkedin_url ||
                this.props.eventData.twitter_url) && {
                url:
                    this.props.eventData.organiser_website ||
                    this.props.eventData.organiser_facebook_url ||
                    this.props.eventData.organiser_linkedin_url ||
                    this.props.eventData.twitter_url
            })
        }
    };

    componentDidMount() {
        const { google_analytics_code, gtm_code, facebook_pixel_code } = this.props.eventData;

        if (google_analytics_code || gtm_code || facebook_pixel_code) {
            this.setState(
                { gaID: google_analytics_code, gtmID: gtm_code, pixelID: facebook_pixel_code },
                () => {
                    if (typeof window !== "undefined") { // Ensure this runs only on the client
                        try {
                            initGA([{ trackingId: google_analytics_code }, { trackingId: GoogleAnalyticsId }]); // Pass both IDs
                        } catch (error) {
                            console.error("Failed to initialize GA:", error);
                        }
                    }
                }
            );
        }

        const { template_type } = this.props.router.query;
        const defaultTemplateType = Number(this.props.eventData.event_template);

        this.setState({
            template_type: [1, 2, 3].includes(Number(template_type))
                ? Number(template_type)
                : defaultTemplateType,
        });
    }


    render() {
        return (
            <>
                <NextSeo
                    title={this.props.eventData.name}
                    titleTemplate="%s"
                    description={
                        this.props.eventData.description
                            ? convertToPlainSeo(this.props.eventData.description)
                            : ""
                    }
                    canonical={`${baseUrl}/${this.props.eventData.event_url}`}
                    openGraph={{
                        url: `${baseUrl}/${this.props.eventData.event_url}`,
                        title: this.props.eventData.name,
                        description: this.props.eventData.description
                            ? convertToPlainSeo(this.props.eventData.description)
                            : "",
                        site_name: "KonfHub",
                        images: [
                            {
                                url: this.props.eventData.poster_thumbnail
                                    ? this.props.eventData.poster_thumbnail
                                    : this.props.eventData.event_poster_url
                                        ? this.props.eventData.event_poster_url
                                        : "",
                                width: 800,
                                height: 600,
                                alt: "KonfHub",
                                type: "image/jpeg"
                            }
                        ]
                    }}
                />
                <StructuredData data={this.structuredData} />
                <Head>
                    {/* LinkedIn Tracking */}
                    {
                        this.props.eventData.event_url === "dubai-fintech-summit-2025" &&
                        <><script
                            dangerouslySetInnerHTML={{
                                __html: `
									_linkedin_partner_id = "5431210"; window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || []; window._linkedin_data_partner_ids.push(_linkedin_partner_id);
								`
                            }}
                        />
                            <script
                                dangerouslySetInnerHTML={{
                                    __html: `
										(function(l) { if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])}; window.lintrk.q=[]} var s = document.getElementsByTagName("script")[0]; var b = document.createElement("script"); b.type = "text/javascript";b.async = true; b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js"; s.parentNode.insertBefore(b, s);})(window.lintrk);
									`
                                }}
                            />
                        </>

                    }
                    {/* Clarity for Dubai AI Festival */}
                    {
                        this?.props?.eventData?.event_url === "dubai-ai-festival" &&
                        <script
                            dangerouslySetInnerHTML={{
                                __html: `
                                    (function(c,l,a,r,i,t,y){
                                    c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                                    t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                                    y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                                    })(window, document, "clarity", "script", "qgsa5qeo8e");
                                `
                            }}
                        />
                    }
                    {/* Google Analytics */}
                    {
                        this.state.gaID && (
                            <>
                                {/*<!-- Google tag (gtag.js) -->*/}
                                <script async src={`https://www.googletagmanager.com/gtag/js?id=${this.state.gaID}`}></script>
                                {/* GA Script */}
                                <script
                                    dangerouslySetInnerHTML={{
                                        __html: `
										window.dataLayer = window.dataLayer || [];
            							function gtag(){dataLayer.push(arguments);}
            							gtag('js', new Date());
            							gtag('config', '${this.state.gaID}');
										`
                                    }}
                                />
                            </>
                        )
                    }
                    {/* Google Tag Manager */}
                    {
                        this.state.gtmID && (
                            <>
                                {/* GTM Script */}
                                <script
                                    dangerouslySetInnerHTML={{
                                        __html: `
									(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
									new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
									j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
									'https://www.googletagmanager.com/gtm.js?id=' + i + dl;f.parentNode.insertBefore(j,f);
								})(window,document,'script','dataLayer','${this.state.gtmID}');
								`
                                    }}
                                />
                            </>
                        )
                    }

                    {/* Facebook Pixel */}
                    {
                        this.state.pixelID && (
                            <>
                                {/* Facebook Pixel Script */}
                                <script
                                    dangerouslySetInnerHTML={{
                                        __html: `
									!function(f,b,e,v,n,t,s)
									{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
									n.callMethod.apply(n,arguments):n.queue.push(arguments)};
									if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
									n.queue=[];t=b.createElement(e);t.async=!0;
									t.src=v;s=b.getElementsByTagName(e)[0];
									s.parentNode.insertBefore(t,s)}(window, document,'script',
									'https://connect.facebook.net/en_US/sdk.js');
									fbq('init', '${this.state.pixelID}');
									fbq('track', 'PageView');
								`
                                    }}
                                />
                            </>
                        )
                    }
                </Head >
                {
                    this.state.template_type === TEMPLATE_TYPES.EventPagePro && <EventPagePro
                        eventUrl={this.props.router.query.eventUrl.toLowerCase()}
                        isReady={this.props.router.isReady}
                        query={getQuery(this.props.router.query)}
                        eventData={this.props.eventData}
                        ticketsData={this.props.ticketsData}
                        groupDiscounts={this.props.groupDiscounts}
                    />
                }
                {
                    this.state.template_type === TEMPLATE_TYPES.EventPageTrendy && <EventPageTrendy
                        eventUrl={this.props.router.query.eventUrl.toLowerCase()}
                        isReady={this.props.router.isReady}
                        query={getQuery(this.props.router.query)}
                        eventData={this.props.eventData}
                        ticketsData={this.props.ticketsData}
                        groupDiscounts={this.props.groupDiscounts}
                    />
                }
                {
                    this.state.template_type === TEMPLATE_TYPES.EventPageMinimalistic && <><Head>
                        <link
                            href="https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700;800&family=Montserrat:wght@200;300&display=swap"
                            rel="stylesheet"
                        />
                    </Head>
                        <EventPageMinimalistic
                            eventUrl={this.props.router.query.eventUrl.toLowerCase()}
                            isReady={this.props.router.isReady}
                            query={getQuery(this.props.router.query)}
                            eventData={this.props.eventData}
                            ticketsData={this.props.ticketsData}
                            groupDiscounts={this.props.groupDiscounts}
                        /></>
                }
            </>
        );
    }

}

/**
 *
 * @param context
 */
export async function getServerSideProps(context) {
    let eventData = false;
    let ticketsData = {
        uncategorized: [],
        categorized: []
    };
    let groupDiscounts = [];
    try {
        if (eventUrlValidation(context.params.eventUrl.toLowerCase())) {
            const [eventRes, ticketRes] = await Promise.all([
                getEventData(context.params.eventUrl.toLowerCase()),
                getTicketData(context.params.eventUrl.toLowerCase())
            ]);
            if (eventRes.status === 200) {
                eventData = eventRes.data;
            }

            const uncategorized = ticketRes?.data?.uncategorized || [];
            groupDiscounts = ticketRes.data.group_discount_details || [];
            let allTickets = [...uncategorized];
            ticketRes.data.categorized = ticketRes.data.categorized.sort(
                (a, b) => a.category_order - b.category_order
            );
            (ticketRes?.data?.categorized || []).forEach(category => {
                category.tickets = category.tickets.map(ticket => {
                    ticket.category = {
                        category_name: category.category_name,
                        category_description: category.category_description,
                        category_id: category.category_id,
                        category_order: category.category_order,
                        is_expanded: category.is_expanded
                    };
                    return ticket;
                });
                allTickets = [...allTickets, ...category.tickets];
            });
            const ticketDetails = allTickets;
            const orderedTicket = ticketDetails.sort((a, b) => {
                // Sort the forms by from orderzz
                return a.ticket_order - b.ticket_order;
            });
            const newTickets = [];
            orderedTicket.map(ticket => {
                if (!ticket.hidden_ticket) newTickets.push(ticket);
                else return;
            }); // temporary
            ticketsData = newTickets;
        }
    } catch (err) {
        console.log(err);
    }

    if (!eventData) {
        return {
            redirect: {
                destination: "/error/404",
                permanent: false
            }
        };
    }

    return {
        props: {
            eventData,
            ticketsData,
            groupDiscounts
        }
    };
}

export default withRouter(EventPage);